//import "fullpage.js"
//import "../node_modules/fullpage.js/dist/fullpage.css"
import "./styles.css"

require('fslightbox');
require("lazyload");

$(document).ready(function () {
  $('#fullpage').fullpage({
    anchors : ['index', 'home', 'web', '3d', 'graphic', 'contact'],
    sectionsColor: ['rgb(23, 23, 23)', 'rgb(23, 23, 23)', 'white', '#BC8F8F', '#3fb5b5', 'rgb(23, 23, 23)'],
    scrollingSpeed : 1000,
    menu: ".nav",
    scrollOverflow: true,
    loopHorizontal: false,
    autoCentered: false,
    });
  $('#arrowDown').click(function(){
    $.fn.fullpage.moveSectionDown();
  });
});

$(document).ready(function(){
  if ($("#p1").mouseover()){
    $("#p1").mouseover(function(){
      $("#header").addClass("visible"),
      $("#arrowDown").removeClass("invisible");
    });
  }
  if ($("#p2").mouseover()){
    $("#p2").mouseover(function(){
      $("#header").addClass("visible"),
      $("#arrowDown").removeClass("invisible");
    });
  }
  if ($("#p3").mouseover()){
    $("#p3").mouseover(function(){
      $("#header").addClass("visible"),
      $("#arrowDown").removeClass("invisible");
    });
  }
  if ($("#p4").mouseover()){
    $("#p4").mouseover(function(){
      $("#header").addClass("visible"),
      $("#arrowDown").removeClass("invisible");
    });
  }
  if ($("#p5").mouseover()){
    $("#p5").mouseover(function(){
      $("#header").addClass("visible"),
      $("#arrowDown").addClass("invisible");
    });
  }
  if ($("#p0").mouseover()){
    $("#p0").mouseover(function(){
      $("#header").removeClass("visible");
    });
  } 
});

$(document).ready(function(){
  $('.lazy').lazyload();
});

$(document).ready(function(){
  $("#cruz").click(function(){
    $("#cruz").toggleClass("giro"),  
    $(".nav2").toggleClass("visible2");
  });
});
